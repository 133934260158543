import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import your icons
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'

export default function Header ({ displaySize, sideNavOpen, sendSideNav, refresh }) {
    const [size, setSize] = useState(displaySize);
    // flag
    const [showSideNav, setShowSideNav] = useState(false);

    // update size
    useEffect(() => {
        setSize(displaySize);
    }, [displaySize]);

    // update side nav flag
    useEffect(() => {
        setShowSideNav(sideNavOpen);
    }, [sideNavOpen]);

    // send nav click event to parent
    useEffect(() => {
        sendSideNav(showSideNav);
    }, [showSideNav]);

    /////////////////////
    // CREATE NAV BAR //
    ///////////////////
    let navIcon = <div className="headerRight flex1"  onClick={() => setShowSideNav(!showSideNav)}>
                    <button className="buttonBlank">
                        <FontAwesomeIcon className="subtitle" icon={showSideNav ? faXmark : faBars } />
                    </button>
                </div>

    let name;
    // animation only happens once
    if (refresh) {
        name = <motion.p className="headerName titleFont titleFontSmall"
                animate={{ x: 0 }}
                initial={{ x: "-50%" }} 
                transition={{ 
                    delay: 2, 
                    duration: 0.8
                }} 
            >
                Cho Laam
            </motion.p>
    } else {
       name = <p className="headerName titleFont titleFontSmall">Cho Laam</p>
    };


    return (
        <div className="header">
            <div className="headerTop flexParent centerItem">
                <Link to={"/"} className="flex1">
                    { name }
                </Link>
                { size == "Small" ? navIcon : null }
            </div>
            <div className="headerBottom"></div>
        </div>
    )
}; 

