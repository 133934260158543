import React from "react";
import { Link } from "react-router-dom";
// import data
import pageData from "./data/pages_config.json";

export default function NavBar ({ }) {

    let pages = Object.keys(pageData).map((k) => {
        let linkName = pageData[k]["link"]
        return (
            <div key={linkName} className="flex1 navUnit spacingTop">
                <Link to={"/" + linkName}>
                    <p className="paddingBot textHover">{k}</p>
                </Link>
            </div>
        );
    });

    return (
        <div className="nav">
            { pages }
        </div>
    );
};