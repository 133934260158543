import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation  } from "react-router-dom";
import './App.css';
// import components
import Header from './Header';
import Home from './pages/Home';
import PortfolioPages from './pages/PortfolioPages'
import Footer from './Footer';
import SideNav from './SideNav';
// dynamic render pages
import DynamicPage from './DynamicPage';


export default function App() {
    const [width, setWindowWidth] = useState(0);
    const [size, setSize] = useState("Large");
    // flag
    const [isRefresh, setIsRefresh] = useState(true);
    const [showSideNav, setShowSideNav] = useState(false);

    // update window width upon resize
    useEffect(() => { 
        updateWidth();

        window.addEventListener("resize", updateWidth);
        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, [])

    // set isRefresh flag as false when user navigates away from homepage
    const location = useLocation();
    useEffect(() => {
        if (location.pathname !== "/") {
            setIsRefresh(false);
        };
    }, [location]);

    // hide side nav when in large display
    useEffect(() => {
        if (size === "Large") {
            setShowSideNav(false);
        };
    }, [size])

    ////////////////
    // FUNCTIONS //
    ///////////////
    // function: update window width and size state
    function updateWidth() {
        const width = window.innerWidth
        setWindowWidth(width)

        // set size based on window width for responsive design
        if (width <= 650) {
            setSize("Small")
        } else if (width > 650) {
            setSize("Large")
        };
    };

    return (
        <>
            <Header displaySize={size} sideNavOpen={showSideNav} sendSideNav={setShowSideNav} refresh={isRefresh} />
            { showSideNav? <SideNav sendSideNav={setShowSideNav} /> : null }
            <Routes>
                <Route exact path='/' element={<Home displaySize={size} refresh={isRefresh} />} />
                <Route path='/:page' element={<DynamicPage displaySize={size} />} />
                <Route path='/portfolio/:project' element={<PortfolioPages displaySize={size} />} />
            </Routes>
            <Footer />
        </>
    );
};
