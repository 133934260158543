import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import your icons
import { faCircleLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
// import data
import portfolioData from '../data/portfolioContent.json'

export default function PortfolioPages({ displaySize }) {
    const navigate = useNavigate();
    const { project } = useParams();
    // for extracting unicode emojis
    const emojiRegex = /&#(\d+);/g; 
    // get data
    const contentData = portfolioData[project]["content"];

    ///////////////////////
    // CREATE BACK ICON //
    //////////////////////
    let backIcon = <motion.div className="backContainer"
                        animate={{ x: 0 }}
                        initial={{ x: "30%" }}
                        transition={{ delay: 0.1, duration: 0.5 }}
                    >
                        <FontAwesomeIcon className="backIcon pointer spacingText" icon={faCircleLeft} onClick={() => navigate("/portfolio")} />
                    </motion.div>

    ///////////////////
    // CREATE TITLE //
    //////////////////
    let title = <motion.div 
                    animate={{ x: 0, opacity: 1 }}
                    initial={{ x: "-20%", opacity: 0 }} 
                    transition={{ duration: 0.5 }}
                >
                    <p className="title textLeft spacingBotLarge">{portfolioData[project]["title"]}</p>
                </motion.div>

    ///////////////////////////////
    // CREATE TABLE OF CONTENTS //
    //////////////////////////////
    // get list of ids
    let idList = [];
    Object.keys(contentData).map((k) => {
        // read name of section
        if (k.includes("section")) {
            let id = contentData[k]["id"];
            if (id) {
                idList.push(id)
            };
        };
    });

    // fill in content line and list
    let contentLine = idList.map((i, index) => {
        return (
            <div className="flex1 centerItem">
                <a className="dot dotPosition centerItem" href={"#" + i}>
                    <p className="textWhite bold">{index + 1}</p>
                </a>
                <p className="textImage textCenter spacingTop15">{i}</p>
            </div>
        )
    });

    let contentList = idList.map((i, index) => {
        return (
            <div className="flexParent">
                <a className="centerItem spacingText" href={"#" + i}>
                    <div className="dot centerItem">
                        <p className="textWhite bold">{index + 1}</p>
                    </div>
                    <p className="textImage">&ensp;{i}</p>
                </a>
            </div>
        )
    });

    // dynamic display 
    let contentTable;
    if (displaySize === "Large") {
        contentTable = <div className="posRelative">
                            <hr className="line" />
                            <div className="flexParent spacingBot">
                                { contentLine }
                            </div>
                        </div>
    } else if (displaySize === "Small") {
        contentTable = <div className="posRelative spacingLeft spacingBot">
                            { contentList }
                        </div>
    };

    /////////////////////
    // CREATE CONTENT //
    ////////////////////
    let content = Object.keys(contentData).map((k) => {
        let section = k;
        let sectionData = contentData[k];
        
        // read name of section
        if (section.includes("section")) {
            // iterate through sections
            let parentCSS = sectionData["css"];
            // find id if any
            let id = sectionData["id"];

            let sectionContent = sectionData["content"].map((l) => {
                let childCSS;
                let sectionDict = Object.keys(l).map((d) => {
                    if (d === "css") {
                        childCSS = l["css"];
                    } else {
                        let dict = l[d]
                        let type = dict["type"];
                        let subtitle = dict["subtitle"]["text"];
                        let subtitleCss = dict["subtitle"]["css"]
                        let text = dict["text"];
                        let textCss = dict["css"];
                        // add animations for subtitles only
                        let anim = subtitleCss.includes("subtitle") ? true : false;

                        // extract emoji if it exists
                        let unicode = emojiRegex.exec(subtitle);
                        if (unicode !== null) {
                            let emoji = String.fromCodePoint(parseInt(unicode[1], 10));
                            subtitle = subtitle.replace(emojiRegex, emoji);
                        };

                        // determine whether content is in TEXT, LIST, IMAGE format
                        if (type === "TEXT") {
                            // iterate through text list
                            let textMap = text.map((t) => {
                                if (t === "BREAK") {
                                    return <br/>
                                } else {
                                    return (
                                        <span>{t}</span>
                                    )
                                }
                            });

                            // include animation depending on flag
                            if (anim) {
                                return (
                                    <p className={textCss}>
                                        <motion.span className={subtitleCss}
                                            variants={{
                                                visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                                                hidden: { opacity: 1, y: "-100%" }
                                            }}
                                            initial="hidden" 
                                            whileInView="visible"
                                        >
                                            {subtitle}
                                        </motion.span>
                                        { textMap }
                                    </p>
                                )
                            } else {
                                return (
                                    <p className={textCss}>
                                        <span className={subtitleCss}>{subtitle}</span>
                                        { textMap }
                                    </p>
                                )
                            };

                        } else if (type.includes("LIST")) {
                            // determine if ordered or unordered list
                            let listType = type.replace("LIST ", "");

                            // iterate through text list
                            let textMap = text.map((t) => {
                                if (t.includes("|")) {
                                    let textList = t.split("|");
                                    // captitalizes the word / words before |
                                    return (
                                        <li>
                                            <span className="bold">{textList[0]}</span>
                                            <span>{textList[1]}</span>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li>{t}</li>
                                    )
                                };

                            });

                            if (listType === "ORDERED") {
                                return (
                                    <>
                                        <p className={subtitleCss}>{subtitle}</p>
                                        <ol>
                                            <p className={textCss}>
                                                { textMap }
                                            </p>
                                        </ol>
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <p className={subtitleCss}>{subtitle}</p>
                                        <ul>
                                            <p className={textCss}>
                                                { textMap }
                                            </p>
                                        </ul>
                                    </>
                                )
                            };
                            
                        } else if (type === "IMAGE") {
                            return (
                                <>
                                    <img className={subtitleCss} src={require("../images/process/" + subtitle)} alt="" />
                                    <p className={textCss}>{text}</p>
                                </>
                            )
                        } else if (type === "IFRAME") {
                            return (
                                <>
                                    <iframe width="100%" height="500" src={subtitle} allowFullScreen></iframe>
                                    <p className={textCss}>{text}</p>
                                </>
                            )
                        };
                    };
                });

                // draw attention to framed divs with animation
                if (childCSS !== undefined && childCSS.includes("textFrame")) {
                    return (
                        <motion.div className={childCSS}
                            variants = {{ 
                                hidden: { x: "100%" },
                                visible: { x: 0, transition: { type: "ease-in-out", bounce: 0.4, duration: 0.8 }}
                            }}
                            initial="hidden" 
                            whileInView="visible"
                        >
                            { sectionDict }
                        </motion.div>
                    )
                } else {
                    return (
                        <div className={childCSS}>
                            { sectionDict }
                        </div>
                    )
                };

            });

            // draw attention to framed divs with animation
            if (parentCSS.includes("textFrame")) {
                return (
                    <motion.div id={id} className={parentCSS}
                        variants = {{ 
                            hidden: { y: "50%" },
                            visible: { y: 0, transition: { type: "spring", bounce: 0.4, duration: 0.8 }}
                        }}
                        initial="hidden" 
                        whileInView="visible"
                    >
                        { sectionContent }
                    </motion.div>
                )
            } else {
                return (
                    <div id={id} className={parentCSS}>
                        { sectionContent }
                    </div>
                )
            };

        // if not a section it is a seperator
        } else {
            return <hr></hr>;
        };
    });

    //////////////////////////
    // CREATE NEXT PROJECT //
    ////////////////////////
    const allProjects = Object.keys(portfolioData);
    // find the next project
    let nextProject;
    const index = allProjects.indexOf(project);
    if (index !== -1 && index < allProjects.length - 1) {
        nextProject = allProjects[index + 1];
    } else {
        nextProject = allProjects[0]; // return to first project
    };

    // function go to next project and scroll to top
    function goNext() {
        navigate("/portfolio/" + nextProject);
        window.scrollTo({ top: 0, behavior: 'auto' });
    };

    let nextProjectButton = <div className="flexParent nextContainer centerItem" onClick={() => goNext()} >
                                <p className="flex5">{portfolioData[nextProject]["title"]}</p>
                                <FontAwesomeIcon className="backIcon pointer flex1" icon={faCaretRight} />
                            </div>


    return (
        <div className="container">
            { backIcon }
            { title }
            { contentTable }
            <div className="noScroll">
                { content }
            </div>
            { nextProjectButton }
        </div>
    );
};