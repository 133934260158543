import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import your icons
import { faLinkedin, faSquareGithub } from '@fortawesome/free-brands-svg-icons'

export default function Footer() {

    return (
        <div id="footer">
            <a href="https://www.linkedin.com/in/cholaam-yuen/" target="_blank">
                <FontAwesomeIcon className="socialIcons" icon={ faLinkedin } />
            </a>
            <a href="https://github.com/ChoLaamY" target="_blank">
                <FontAwesomeIcon className="socialIcons" icon={ faSquareGithub } />
            </a>
        </div>
    )

}