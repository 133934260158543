import React from "react";
import { motion } from "framer-motion";
// import data
import blogData from "../data/blogContent.json"

export default function Blog() {
    // for extracting unicode emojis
    const emojiRegex = /&#(\d+);/g;


    /////////////////////
    // CREATE CONTENT //
    ////////////////////
    let content = Object.keys(blogData).map((k) => {
        let blogPost = blogData[k];
        let title = blogPost["title"];
        let date = blogPost["date"];
        let location = blogPost["location"];

        let headers = <div className="spacingText">
                        <p className="textLarge bold">{ title }</p>
                        <p>{ date }</p>
                        <p className="italics">{ location }</p>
                    </div>

        let sectionContent = Object.keys(blogPost["content"]).map((i) => {
            let section = i;
            let sectionData = blogPost["content"][i];

            // read name of section
            if (section.includes("section")) {
                // iterate through sections
                let parentCSS = sectionData["css"];
                // find id if any
                let id = sectionData["id"];

                let sectionContent = sectionData["content"].map((l) => {
                    let childCSS;
                    let sectionDict = Object.keys(l).map((d) => {
                        if (d === "css") {
                            childCSS = l["css"];
                        } else {
                            let dict = l[d]
                            let type = dict["type"];
                            let subtitle = dict["subtitle"]["text"];
                            let subtitleCss = dict["subtitle"]["css"]
                            let text = dict["text"];
                            let textCss = dict["css"];
                            // add animations for subtitles only
                            let anim = subtitleCss.includes("subtitle") ? true : false;

                            // extract emoji if it exists
                            let unicode = emojiRegex.exec(subtitle);
                            if (unicode !== null) {
                                let emoji = String.fromCodePoint(parseInt(unicode[1], 10));
                                subtitle = subtitle.replace(emojiRegex, emoji);
                            };

                            // determine whether content is in TEXT, LIST, IMAGE format
                            if (type === "TEXT") {
                                // iterate through text list
                                let textMap = text.map((t) => {
                                    if (t === "BREAK") {
                                        return <br/>
                                    } else {
                                        return (
                                            <span>{t}</span>
                                        )
                                    }
                                });

                                // include animation depending on flag
                                if (anim) {
                                    return (
                                        <p className={textCss}>
                                            <motion.span className={subtitleCss}
                                                variants={{
                                                    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                                                    hidden: { opacity: 1, y: "-100%" }
                                                }}
                                                initial="hidden" 
                                                whileInView="visible"
                                            >
                                                {subtitle}
                                            </motion.span>
                                            { textMap }
                                        </p>
                                    )
                                } else {
                                    return (
                                        <p className={textCss}>
                                            <span className={subtitleCss}>{subtitle}</span>
                                            { textMap }
                                        </p>
                                    )
                                };

                            } else if (type.includes("LIST")) {
                                // determine if ordered or unordered list
                                let listType = type.replace("LIST ", "");

                                // iterate through text list
                                let textMap = text.map((t) => {
                                    if (t.includes("|")) {
                                        let textList = t.split("|");
                                        // captitalizes the word / words before |
                                        return (
                                            <li>
                                                <span className="bold">{textList[0]}</span>
                                                <span>{textList[1]}</span>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li>{t}</li>
                                        )
                                    };

                                });

                                if (listType === "ORDERED") {
                                    return (
                                        <>
                                            <p className={subtitleCss}>{subtitle}</p>
                                            <ol>
                                                <p className={textCss}>
                                                    { textMap }
                                                </p>
                                            </ol>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <p className={subtitleCss}>{subtitle}</p>
                                            <ul>
                                                <p className={textCss}>
                                                    { textMap }
                                                </p>
                                            </ul>
                                        </>
                                    )
                                };
                                
                            } else if (type === "IMAGE") {
                                return (
                                    <>
                                        <img className={subtitleCss} src={require("../images/blog/" + subtitle)} alt="" />
                                        <p className={textCss}>{text}</p>
                                    </>
                                )
                            } else if (type === "IFRAME") {
                                return (
                                    <>
                                        <iframe width="100%" height="500" src={subtitle} allowFullScreen></iframe>
                                        <p className={textCss}>{text}</p>
                                    </>
                                )
                            };
                        };
                    });

                    // draw attention to framed divs with animation
                    if (childCSS !== undefined && childCSS.includes("textFrame")) {
                        return (
                            <motion.div className={childCSS}
                                variants = {{ 
                                    hidden: { x: "100%" },
                                    visible: { x: 0, transition: { type: "ease-in-out", bounce: 0.4, duration: 0.8 }}
                                }}
                                initial="hidden" 
                                whileInView="visible"
                            >
                                { sectionDict }
                            </motion.div>
                        )
                    } else {
                        return (
                            <div className={childCSS}>
                                { sectionDict }
                            </div>
                        )
                    };

                });

                // draw attention to framed divs with animation
                if (parentCSS.includes("textFrame")) {
                    return (
                        <motion.div id={id} className={parentCSS}
                            variants = {{ 
                                hidden: { y: "50%" },
                                visible: { y: 0, transition: { type: "spring", bounce: 0.4, duration: 0.8 }}
                            }}
                            initial="hidden" 
                            whileInView="visible"
                        >
                            { sectionContent }
                        </motion.div>
                    )
                } else {
                    return (
                        <div id={id} className={parentCSS}>
                            { sectionContent }
                        </div>
                    )
                };

            // if not a section it is a seperator
            } else {
                return <hr></hr>;
            };

        })

        return (
            <div className="textFrame borderBlue spacingBot spacingTop2">
                { headers }
                { sectionContent }
            </div>
        )
    });

    
    return (
        <div className="container">
            <p className="centerItem subtitle">UX Blog</p>
            <p className="centerItem spacingBot">Sharing some everyday encounters with user experience.</p>

            { content }
        </div>
    );
};