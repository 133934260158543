import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
// import components
import NavBar from "../NavBar";
// import image
import profilePic from ".././images/profile.png";


export default function Home({ displaySize, refresh }) {
    const [size, setSize] = useState(displaySize);

    // update size
    useEffect(() => {
        setSize(displaySize);
    }, [displaySize]);


    //////////////////////////
    // CREATE ANIM CONTENT //
    /////////////////////////
    let introImage = <motion.img src={profilePic} alt="profile" className="profileImage" 
                        animate={{
                            opacity: [0, 0.2, 0.4, 0.6, 0.8, 1]
                        }}
                        initial={{ opacity: 0 }} 
                        transition={{ 
                            delay: refresh ? 2.5 : 0, 
                            duration: refresh ? 0.8 : 0.5
                        }} 
                    />

    let introTitle;
    // animation only happens once
    if (refresh) {
        introTitle = <motion.p className="titleFont spacingTop spacingBot typewriter"
                        initial={{ 
                            scale: displaySize === "Small" ? 1.5: 2, 
                            x: "-30%" 
                        }} 
                        animate={{ scale: 1, x: 0 }} 
                        transition={{ delay: 2, duration: 0.8 }} 
                    >
                        Hello! I'm Cho Laam.
                    </motion.p>
    } else {
        introTitle = <motion.p className="titleFont spacingTop spacingBot"
                        animate={{ y: 0 }}
                        initial={{ y: "50%" }} 
                        transition={{ 
                            duration: 0.5
                        }} 
                    >
                        Hello! I'm Cho Laam.
                    </motion.p>
    };

    let introText = <motion.div
                        initial={{ 
                            opacity: 0,
                            y: refresh ? 0 : "50%"
                        }} 
                        animate={{ 
                            opacity: [0, 0.2, 0.4, 0.6, 0.8, 1],
                            y: 0
                        }}    
                        transition={{  
                            delay: refresh ? 2.5 : 0, 
                            duration: refresh ? 0.8 : 0.45
                        }}  
                    >
                        <p className="spBotDynamic2">
                            I am a software developer based in New York City. Currently working at an AI startup, Graphen Inc. 
                        </p>
                        <p className="spBotDynamic2">
                            Aspiring UX researcher, driven to solve real-world problems by understanding users and designing human-centered applications. 
                        </p>
                    </motion.div>

    //////////////////////
    // DISPLAY CONTENT //
    /////////////////////
    // based on size
    let content;
    if (size === "Small") {
        content = <div className="container">
                    <div className="flexParent">
                        <div className="flex1 portraitImage">
                            { introImage }
                        </div>
                        <div className="flex2 spacingLeft centerVertical">
                            { introTitle }
                        </div>
                    </div>
                    
                    <div className="spacingTop">
                        { introText }
                    </div>
                </div>
    } else {
        content = <div className="container flexParent">
                    <div className="flex1 centerVertical">
                        { introImage }
                    </div>
                    <div className="flex2 spacingLeft">
                        { introTitle }
                        { introText }
                    </div>
                </div>
    };

    return (
        <>
            { size === "Large" ? <NavBar/> : null }
            { content }
        </>
    );
};