import React from "react";
import { useParams, Link } from "react-router-dom";
// import components
import Portfolio from './pages/Portfolio';
import Research from './pages/Research';
import AboutMe from './pages/AboutMe';
import Blog from './pages/Blog';

export default function DynamicPage({ displaySize }) {
    const { page } = useParams();

    // render different components based on browser route after /
    switch (page) {
        case "portfolio":
            return  <Portfolio />;
        case "research": 
            return <Research />;
        case "about":
            return <AboutMe size={displaySize} />;
        case "blog":
            return <Blog />;
        default:
            return <></>;
    }

};

// export function PortfolioPages() {
//     const { project } = useParams();

//     switch (project) {
//         case "voiceKiosk":
//             return <VoiceKiosk />;
//         case "touchOrdering":
//             return <TouchOrdering />;
//         default:
//             return <></>
//     }
// }