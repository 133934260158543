import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
// import data
import portfolioData from '../data/portfolioContent.json'

export default function Portfolio() {

    let portfolioItems = Object.keys(portfolioData).map((k) => {
        let linkName = k;
        let itemData = portfolioData[k];
        let image = itemData["image"];
        let title = itemData["title"];

        return (
            <motion.div
                animate={{ y: 0, opacity: 1 }}
                initial={{ y: "-10%", opacity: 0 }} 
                transition={{ delay: 0.1, duration: 0.5 }}
            >
                <Link className="imageContainer" to={"/portfolio/" + linkName}>
                    <img className="gridImages" src={require('../images/' + image)} alt="" />
                    <div className="imageOverlay">
                        <div className="textOverlay divBottom">
                            <p className="subtitle spacingTop spacingBot textLeft bold">{title}</p>
                        </div>
                    </div>
                </Link>
            </motion.div>
        )
    });

    return (
        <div className="container">
            <div className="centerItem spacingBot">
                <p className="subtitle">Portfolio</p>
            </div>

            <div className="contentGrid">
                { portfolioItems }
            </div>
        </div>
    );
};