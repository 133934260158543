import React from "react";

export default function Blog() {
    return (
        <div className="container">
            <div className="centerItem spacingBotLarge">
                <p className="subtitle">Research</p>
            </div>

            <p className="centerItem">Content in development...</p>
        </div>
    );
};