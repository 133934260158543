import React from "react";
import { motion } from "framer-motion";
// import image
import portrait from ".././images/portrait.png";

export default function AboutMe({ size }){
    
    let text = <motion.div
                    className="textGrid"
                    animate={{ x: 0, opacity: 1 }}
                    initial={{ x: "-20%", opacity: 0 }} 
                    transition={{ delay: 0.1, duration: 0.5 }}
                >
                    <p>
                        Studying human behavior and engaging in creative pursuits has always driven me. 
                        I see user experience research at the intersection of my interests in usability and design. 
                    </p>
                    <p className="italics">My goal as a researcher is to gain a deeper understanding of users to better develop solutions through feature and interface design.</p>
                    <p>Outside of design and coding, I enjoy cooking, exploring restaurants in the city, and snuggling up a good book.</p>
                </motion.div>

    let image = <motion.div className="centerItem spacingBot"
                    animate={{ x: 0, opacity: 1 }}
                    initial={{ x: "20%", opacity: 0 }} 
                    transition={{ delay: 0.1, duration: 0.5 }}
                >
                    <img src={portrait} alt="portrait" className="quarterImage" />
                </motion.div>

    // change content layout depending on screen size
    let content;
    if (size === "Large") {
        content = <div className="col2">
                    { text }
                    { image }
                </div>
    } else if (size === "Small") {
        content = <div>
                    { image }
                    { text }
                </div>
    }
    return (
        <div className="container">
            <div className="centerItem spacingBotLarge">
                <p className="subtitle">About Me</p>
            </div>

            { content }
        </div>
    );
};