import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import data
import pagesData from "./data/pages_config.json";
// import component
import Footer from "./Footer";

export default function SideNav ({ sendSideNav }) {

    let pages = Object.keys(pagesData).map((k) => {
        let link = pagesData[k]["link"];
        return (
            <div className="navUnit spacingTop centerItem">
                <Link to={"/" + link} onClick={() => sendSideNav(false)}>
                    <p className="textLarge paddingBot textHover">{k}</p>
                </Link>
            </div>
        );
    });

    return (
        <div className="sideNavContainer">
            { pages }
            <Footer />
        </div>
    )
};